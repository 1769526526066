import React from "react";

const Home = React.lazy(() => import("./components/pages/Home"));
const RSA = React.lazy(() => import("./components/pages/RSA"));
const IN = React.lazy(() => import("./components/pages/Linkedin"));
const FB = React.lazy(() => import("./components/pages/Facebook"));
const INSTA = React.lazy(() => import("./components/pages/Instagram"));
const RE = React.lazy(() => import("./components/pages/Redit"));
const BG = React.lazy(() => import("./components/pages/Blog"));
const BGC = React.lazy(() => import("./components/pages/CreateBlog"));
const BGE = React.lazy(() => import("./components/pages/EditBlog"));
const BGV = React.lazy(() => import("./components/pages/ViewPost"));
// const AU = React.lazy(() => import("./components/pages/Login"));
const REG = React.lazy(() => import("./components/pages/Register"));
const SET = React.lazy(() => import("./components/pages/Settings"));
const PRIVACY = React.lazy(() => import("./components/pages/Privacy"));
const TERMS = React.lazy(() => import("./components/pages/Terms"));
const PaymentSuccess = React.lazy(() =>
  import("./components/pages/PaymentSuccess")
);
const PaymentFail = React.lazy(() => import("./components/pages/PaymentFail"));

const routes = [
  { path: "/home", name: "Home", element: Home },
  { path: "/rsa-google", name: "RSA Google", element: RSA },
  { path: "/post-linkedin", name: "POST LinkedIn", element: IN },
  { path: "/post-facebook", name: "POST LinkedIn", element: FB },
  { path: "/post-instagram", name: "POST LinkedIn", element: INSTA },
  { path: "/post-reddit", name: "POST Reddit", element: RE },
  { path: "/blog", name: "Blog", element: BG },
  { path: "/write", name: "Blog", element: BGC },
  { path: "/blog/:id/", name: "View", element: BGV },
  { path: "/post/edit/:id/", element: BGE },
  // { path: "/login", name: "Auth", element: AU },
  { path: "/register", name: "Register", element: REG },
  { path: "/settings", name: "Settings", element: SET },
  { path: "/payment/success", name: "Settings", element: PaymentSuccess },
  { path: "/payment/fail", name: "Settings", element: PaymentFail },
  { path: "/privacy", name: "Privacy", element: PRIVACY },
  { path: "/terms", name: "Terms", element: TERMS },
];

export default routes;
