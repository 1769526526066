// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import selectedReducer from "./slices/selectedSlice";

import activeTabReducer from "./slices/activeTabSlice";
import settingsReducer from "./slices/settingsSlice";
import themeReducer from "./slices/themeSlice";
import carouselsReducer from "./slices/carouselsSlice";
import waterMarkReducer from "./slices/waterMarkSlice";
import planReducer from "./slices/planSlice";

export const store = configureStore({
  reducer: {
    selected: selectedReducer,
    activeTab: activeTabReducer,
    settings: settingsReducer,
    theme: themeReducer,
    carousels: carouselsReducer,
    waterMark: waterMarkReducer,
    plan: planReducer,
  },
});

export default store;
