import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  colors: {
    primary: "#4A4FC7",
    secondary: "#797B96",
    accent: "#fff",
  },
  background: {
    type: "circles",
    active: true,
  },
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeColors: (state, action) => {
      state.colors = { ...state.colors, ...action.payload };
    },
    setThemeBackground: (state, action) => {
      state.background = { ...state.background, ...action.payload };
    },
  },
});

export const { setThemeColors, setThemeBackground } = themeSlice.actions;
export default themeSlice.reducer;
