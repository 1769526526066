import { createContext } from "react";

const data = {
    token: "",
    isAuth: false,
    isAuthor: false,
    strapi_token: "",
    page: 1,
    redirect: "/login"
}

const actions = {
    ADD_TOKEN: "ADD_TOKEN",
    REMOVE_TOKEN: "REMOVE_TOKEN",
    CHANGE_AUTH: "CHANGE_AUTH",
    CURRENT_PAGE: "CURRENT_PAGE",
    CHANGE_TOKEN: "CHANGE_TOKEN",
    REDIRECT_PAGE: "REDIRECT_PAGE"
}

function reducer(state, action) {
    if (actions.ADD_TOKEN === action.type) {
        const token = action.value.token;
        const strapi_token = action.value.strapi_token;
        const isAuthor = action.value.author;
        localStorage.setItem("token", token);
        localStorage.setItem("rol", isAuthor ? 10 : 100);
        return { token: token, isAuth: true, strapi_token: strapi_token }
    }
    else if (actions.REMOVE_TOKEN === action.type) {
        localStorage.removeItem("token");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("strapi_token");
        localStorage.removeItem("strapi_rol");
        localStorage.removeItem("strapi_author");
        return { token: "", strapi_token: "", isAuth: false }
    }
    else if (actions.CHANGE_AUTH === action.type) {
        return { ...state, isAuth: action.value.isAuth }
    } else if (actions.CURRENT_PAGE === action.type) {
        return { ...state, page: action.value.page }
    } else if (actions.CHANGE_TOKEN === action.type) {
        localStorage.setItem("strapi_token", action.value.strapi_token);
        return { ...state, strapi_token: action.value.strapi_token }
    } else if (actions.REDIRECT_PAGE === action.type) {
        return { ...state, redirect: action.value.redirect }
    }
    else {
        return { ...state }
    }
}

const ADcontext = createContext();

export { data, reducer, actions, ADcontext }