import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPlanStatus: (state, action) => action.payload,
  },
});

export const { setPlanStatus } = planSlice.actions;
export default planSlice.reducer;
