import { createSlice } from "@reduxjs/toolkit";

const initialState = true;

export const waterMarkSlice = createSlice({
  name: "waterMark",
  initialState,
  reducers: {
    setWaterMarkStatus: (state, action) => action.payload,
  },
});

export const { setWaterMarkStatus } = waterMarkSlice.actions;
export default waterMarkSlice.reducer;
