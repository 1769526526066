import { createSlice } from "@reduxjs/toolkit";

const initialState = "content";

export const activeTabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    setActiveTab: (state, action) => action.payload,
  },
});

export const { setActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;
