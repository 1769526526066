import { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { ADcontext } from "../context/adContext";

const useAuthRedirect = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const { dispatch } = useContext(ADcontext);

    useEffect(() => {
        const r = async () => {
            if (!isLoading && !isAuthenticated) {
                navigate('/home');
            } else {
                if (!isAuthenticated) return
                const token = await getAccessTokenSilently();
                localStorage.setItem("token", token);
                dispatch({
                    type: "ADD_TOKEN",
                    value: {
                        token: token,
                        author: false,
                    },
                });
                dispatch({
                    type: "CHANGE_AUTH",
                    value: {
                        isAuth: true,
                    },
                });
            }
        }
        r()
    }, [isAuthenticated, isLoading, navigate]);
};

export default useAuthRedirect;