import { Dropdown } from "react-bootstrap";
import "./DropdowNavBar.css";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { ADcontext } from "../../context/adContext";
import { useAuth0 } from "@auth0/auth0-react";

export default function DropdowNavBar() {
  const [show, setShow] = useState(false);

  const handleSelect = (eventKey) => {
    setShow(false);
  };

  return (
    <Dropdown show={show} onSelect={handleSelect}>
      <Dropdown.Toggle
        style={{ color: "#00ca90" }}
        variant="none"
        className="nav-link fw-bold fs-6 text-center w-100"
        id="dropdown-basic"
        onClick={() => setShow(!show)}
      >
        AdGenius
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu-dropdown" align="start">
        <Link
          className="item_menu text-decoration-none text-center text-md-start"
          to={"/post-linkedin"}
          onClick={() => setShow(false)}
        >
          Linkedin Post
        </Link>
        <Link
          className="item_menu text-decoration-none text-center text-md-start"
          to={"/post-reddit"}
          onClick={() => setShow(false)}
        >
          Reddit Post
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function DropdowNavBarProfile({ handlrePermission }) {
  const [show, setShow] = useState(false);
  const { dispatch } = useContext(ADcontext);
  const { logout } = useAuth0();

  const handleSelect = (eventKey) => {
    setShow(false);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin + "/home",
      },
    });

  const handlerLogout = () => {
    logoutWithRedirect();
    dispatch({ type: "REMOVE_TOKEN" });
    handlrePermission();
  };

  return (
    <Dropdown show={show} onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="none"
        className="nav-link text-light fw-bold fs-6 text-center w-100 ms-2"
        id="dropdown-basic"
        onClick={() => setShow(!show)}
      >
        <i className="bi bi-person"></i> Profile
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu-dropdown" align="start">
        <Link
          className="item_menu text-dark text-decoration-none text-center text-md-start"
          to={"/settings"}
          onClick={() => setShow(false)}
        >
          Settings
        </Link>
        <Link
          className="item_menu text-dark text-decoration-none text-center text-md-start"
          to={"/"}
          onClick={handlerLogout}
        >
          Logout
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}

// DropdowNavBarProfile.propTypes = {
//   handlrePermission: PropTypes.func.isRequired(),
// };
