import React, { Suspense, useMemo, useReducer } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";
import DefaultLayout from "./components/templates/DefaultLayout";
import { ADcontext, reducer, data } from "./context/adContext";
import { Toaster } from "react-hot-toast";

const loading = (
  <div className="my-5 pt-3 text-center">
    <div className="spinner-border text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function App() {
  const [state, dispatch] = useReducer(reducer, data);

  const value = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <ADcontext.Provider value={value}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Toaster position="top-center" reverseOrder={false} />
          <Routes>
            {/*  <Route exact path="/login" name="Login Page" element={<LoginPage />} /> */}
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ADcontext.Provider>
  );
}

export default App;
